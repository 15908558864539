import { MenuItemType } from "@layout/header/Menu/MenuItem/types";

import { IconTypeEnum } from "@/components/common/icon";

export const MENU_DATA: MenuItemType[] = [
  {
    title: `Каталог экспертов`,
    link: `/catalog`,
    icon: IconTypeEnum.Profile,
    subItems: [
      {
        title: `Тарологи`,
        link: `/catalog/tarologi`,
      },
      {
        title: `Астрологи`,
        link: `/catalog/astrologi`,
      },
      {
        title: `Нумерологи`,
        link: `/catalog/numerologi`,
      },
      {
        title: `Матрица судьбы`,
        link: `/catalog/matrica-sudby`,
      },
      {
        title: `Расстановки`,
        link: `/catalog/rasstanovki`,
      },
      {
        title: `Психологическое консультирование`,
        link: `/catalog/psihologicheskoe-konsultirovanie`,
      },
      {
        title: `Рунологи`,
        link: `/catalog/runologi`,
      },
      {
        title: `Чакрологи`,
        link: `/catalog/chakri`,
      },
    ],
  },
  {
    title: `Услуги астрологов`,
    link: `/`,
    icon: IconTypeEnum.Spheres,
    subItems: [
      {
        title: `Натальная карта`,
        link: `/catalog/natalnaya-karta`,
        icon: IconTypeEnum.Travel,
      },
      {
        title: `Синастрия`,
        link: `/catalog/sinastriya`,
        icon: IconTypeEnum.Compatibility,
      },
    ],
  },
  {
    title: `Расклады Таро`,
    link: `/`,
    icon: IconTypeEnum.Fan,
    subItems: [
      {
        title: `Расклад Таро на судьбу`,
        link: `/category/gadanie/na-kartah-taro/sudba`,
      },
      {
        title: `Расклад Таро на измену`,
        link: `/category/gadanie/na-kartah-taro/na-izmenu`,
      },
      {
        title: `Расклад Таро на отношения`,
        link: `/category/gadanie/na-kartah-taro/na-ljubov-i-otnoshenija`,
      },
      {
        title: `Расклад Таро на мужчину`,
        link: `/category/gadanie/na-kartah-taro/na-muzhchinu`,
      },
      {
        title: `Расклад Таро на женщину`,
        link: `/category/gadanie/na-kartah-taro/na-zhenshchinu`,
      },
      {
        title: `Расклад Таро на будущее`,
        link: `/category/gadanie/na-kartah-taro/na-budushhee`,
      },
      {
        title: `Расклад Таро «Да-Нет»`,
        link: `/category/gadanie/na-kartah-taro/da-net`,
      },
      {
        title: `Расклад на картах Таро Уэйта`,
        link: `/category/gadanie/na-kartah-taro/rajdera-uehjta`,
      },
      {
        title: `Расклад Таро на ситуацию`,
        link: `/category/gadanie/na-kartah-taro/na-situaciju`,
      },
      {
        title: `Расклад Таро на неделю`,
        link: `/category/gadanie/na-kartah-taro/na-nedelju`,
      },
      {
        title: `Расклад Таро «Карта дня»`,
        link: `/category/gadanie/na-kartah-taro/na-den`,
      },
      {
        title: `Расклад Таро на 2024 год`,
        link: `/category/gadanie/na-kartah-taro/year`,
      },
    ],
  },
  {
    title: `Этика`,
    link: `/etika`,
    icon: IconTypeEnum.Info,
  },
];
