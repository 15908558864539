import { gql } from "@apollo/client";

export const END_SESSION = gql`
  mutation endSession($sessionID: Int!) {
    endSession(sessionID: $sessionID) {
      durationInMinutes
      totalPrice
    }
  }
`;
