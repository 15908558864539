export enum IconTypeEnum {
  Profile = "Profile",
  Menu = "Menu",
  Home = "Home",
  Category = "Category",
  Spheres = "Spheres",
  Fan = "Fan",
  Search = "Search",
  Fav = "Fav",
  Fav2 = "Fav2",
  Fav2Plus = "Fav2Plus",
  Left = "Left",
  Right = "Right",
  Dropdown = "Dropdown",
  Close = "Close",
  Filter = "Filter",
  Loading = "Loading",
  Phone = "Phone",
  PhoneBold = "PhoneBold",
  Call = "Call",
  CallError = "CallError",
  CallIncoming = "CallIncoming",
  CallEnd = "CallEnd",
  Video = "Video",
  VideoBold= "VideoBold",
  VideoOff = "VideoOff",
  VideoContour = "VideoContour",
  VideoOffContour = "VideoOffContour",
  Microphone = "Microphone",
  MicrophoneOff = "MicrophoneOff",
  Audio = "Audio",
  Sound = "Sound",
  Gift = "Gift",
  Tick = "Tick",
  Lock = "Lock",
  FavPerson = "FavPerson",
  Usd = "Usd",
  Facebook = "Facebook",
  Google = "Google",
  Vk = "Vk",
  Ok = "Ok",
  Tg = "Tg",
  Dz = "Dz",
  Time = "Time",
  File = "File",
  Attachment = "Attachment",
  Emoji = "Emoji",
  More = "More",
  Minimize = "Minimize",
  Expand = "Expand",
  EyeOn = "EyeOn",
  EyeOff = "EyeOff",
  User = "User",
  Plus = "Plus",
  Payment = "Payment",
  VideoOn = "VideoOn",
  Delete = "Delete",
  Card = "Card",
  ProtectedCard = "ProtectedCard",
  Number = "Number",
  Error = "Error",
  Exclamation = "Exclamation",
  Email = "Email",
  EmailBlue = "EmailBlue",
  Copy = "Copy",
  WhatsApp = "WhatsApp",
  PayMastercard = "PayMastercard",
  PayVisa = "PayVisa",
  PayApple = "PayApple",
  PayMir = "PayMir",
  PayYandex = "PayYandex",
  New = "New",
  Reload = "Reload",
  Faq = "Faq",
  Family = "Family",
  Purpose = "Purpose",
  Work = "Work",
  Compatibility = "Compatibility",
  Travel = "Travel",
  Finance = "Finance",
  Other = "Other",
  LogOut = "LogOut",
  DropdownStrong = "DropdownStrong",
  Share = "Share",
  Shield = "Shield",
  Fast = "Fast",
  Play = "Play",
  Pause = "Pause",
  Calendar = "Calendar",
  Fire = "Fire",
  Tickets = "Tickets",
  Export = "Export",
  Paper = "Paper",
  Dress = "Dress",
  Concentration = "Concentration",
  Bed = "Bed",
  Food = "Food",
  Info = "Info",
  Certificate = "Certificate",
  Smiley = "Smiley",
  Invitation = "Invitation",
  ShareTg = "ShareTg",
  ShareVk = "ShareVk",
  ShareOk = "ShareOk",
  ShareFb = "ShareFb",
  ShareWApp = "ShareWApp",
  ShareTwitter = "ShareTwitter",
  Chat = "Chat",
  Send = "Send",
  ChatIncoming= "ChatIncoming"
}

export enum IconSizeEnum {
  Size80 = "80",
  Size64 = "64",
  Size48 = "48",
  Size40 = "40",
  Size36 = "36",
  Size32 = "32",
  Size28 = "28",
  Size24 = "24",
  Size20 = "20",
  Size16 = "16",
  Size12 = "12",
}

export type IconProps = {
  type: IconTypeEnum | string;
  size?: IconSizeEnum;
  title?: string;
  desc?: string;
  [x: string]: any;
};
