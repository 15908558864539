import React, { useState } from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "../icon";

import { PasswordInputProps } from "./types";

const PasswordInput = ({ className = "", ...rest }: PasswordInputProps) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <>
      <input sentry-mask {...rest} className={className} type={isPasswordShown ? "text" : "password"} />
      <Icon
        type={isPasswordShown ? IconTypeEnum.EyeOff : IconTypeEnum.EyeOn}
        onClick={() =>
          setIsPasswordShown((oldVal) =>
            !oldVal)}
        size={IconSizeEnum.Size24}
        className="input-group__icon input-group__icon--eye"
      />
    </>
  );
};

export default PasswordInput;
