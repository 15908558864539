import React from "react";

import "./styles.scss";
import { UserInfoProps } from "./types";

const UserInfo = (props: UserInfoProps) => {
  const {
    isConnecting = false, title, subtitle, subtitleNewLine, avatar, children, className, ...rest
  } = props;

  return (
    <div className={`user-info ${isConnecting ? "user-info--await" : ""} ${className || ""}`} {...rest}>
      {!!avatar && avatar}
      <div className="user-info__inner">
        {title && <div className="user-info__name">{title}</div>}
        {!!subtitle && <div className={`user-info__specialization ${isConnecting ? "--show" : ""}`}>{subtitle}</div>}
        {!!subtitleNewLine && <div className={`user-info__specialization user-info__subtitle-new-line ${isConnecting ? "--show" : ""}`}>{subtitleNewLine}</div>}
      </div>
      {children}
    </div>
  );
};

export default UserInfo;
