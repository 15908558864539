import React, { useEffect, useState } from "react";

import "./styles.scss";
import { TOOLTIP_SHOW_TIMEOUT } from "./constants";
import { TooltipPositionEnum, TooltipProps } from "./types";

export const Tooltip = (props: TooltipProps) => {
  const {
    text, position, children, isHoverEvent, minWidth, isAlwaysShown = false, ...rest
  } = props;
  const [isShown, setIsShown] = useState(isAlwaysShown);

  const contentClickHandler = () => {
    setIsShown(true);
  };

  useEffect(() => {
    const closeTooltip = !isAlwaysShown && setTimeout(() =>
      setIsShown(false), TOOLTIP_SHOW_TIMEOUT);

    return () => {
      if (closeTooltip) {
        clearTimeout(closeTooltip);
      }
    };
  }, [isAlwaysShown, isShown]);

  const handleMouseEnter = () => {
    if (isHoverEvent) {
      setIsShown(true);
    }
  };

  const handleMouseLeave = () => {
    if (isHoverEvent) {
      setIsShown(false);
    }
  };

  return (
    <div className="tooltip__container" {...rest}>
      <div
        className="tooltip__content"
        onClick={contentClickHandler}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {isShown && (
        <div
          className={`tooltip__message ${position.toLowerCase()}${isShown ? " is-shown" : ""}`}
          style={{ minWidth: `${minWidth}` }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export { TooltipPositionEnum };
