import { ReactElement } from "react";

export enum TooltipPositionEnum {
  Top = "Top",
  Bottom = "Bottom"
  // todo: implement other positions when needed
}

export type TooltipProps = {
  text: string;
  position: TooltipPositionEnum;
  children: ReactElement;
  isHoverEvent?: boolean;
  minWidth?: string;
  isAlwaysShown?: boolean;
  [x: string]: any;
};
