import { detect } from "detect-browser";

import { BrowserNameEnum, DeviceTypeEnum } from "@/globalTypes";

export const browserInfo = detect();

export const isBrowser = () =>
  typeof window !== "undefined";

export const isWebView = () =>
  isBrowser()
    && (window.navigator.standalone || window.matchMedia("(display-mode: standalone)").matches);

export const detectDeviceType = () => {
  const {
    userAgent: ua,
    maxTouchPoints: tp,
  } = navigator;
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return DeviceTypeEnum.Mobile;
  }
  if (
    /(tablet|ipad|iPad|playbook|silk)|(android(?!.*mobi))/i.test(ua)
    || (tp > 2 && /(Safari)/i.test(ua))
  ) {
    return DeviceTypeEnum.Tablet;
  }
  return DeviceTypeEnum.Desktop;
};

export const browserDetect = () => {
  if (!browserInfo) {
    return "";
  }

  if (browserInfo.name.match(/chrome|chromium|crios/i)) {
    return BrowserNameEnum.Chrome;
  }
  if (browserInfo.name.match(/firefox|fxios/i)) {
    return BrowserNameEnum.Firefox;
  }
  if (browserInfo.name.match(/safari|ios/i)) {
    return BrowserNameEnum.Safari;
  }
  if (browserInfo.name.match(/yandexbrowser/i)) {
    return BrowserNameEnum.Yandex;
  }
  if (browserInfo.name.match(/edg/i)) {
    return BrowserNameEnum.Edge;
  }
  return browserInfo.name;
};

export const printObjectEntries = (data: any, title: string = "", targetId: string = "logs") => {
  const target = document.getElementById(targetId);
  if (!target || !data) {
    return;
  }

  target.innerHTML += `<br>${title}<br>${JSON.stringify(data, Object.keys(data))}`;
  target.innerHTML += `<br><br>${JSON.stringify(data, Object.values(data))}`;
};

export const printObject = (data: any, title: string = "", targetId: string = "logs") => {
  const target = document.getElementById(targetId);
  if (!target || !data) {
    return;
  }

  const replacerFunc = () => {
    const visited = new WeakSet();
    return (key: any, value: object | null) => {
      if (typeof value === "object" && value !== null) {
        if (visited.has(value)) {
          return;
        }
        visited.add(value);
      }
      return value;
    };
  };

  target.innerHTML += `<br><br>${title}<br>${JSON.stringify(data, replacerFunc())}`;
};
