import { CountryProps } from "./types";

export const COUNTRIES_PHONE_MASKS = require("countries-phone-masks");

export const selectablesCountries = [
  {
    name: "Россия",
    iso: "ru",
  },
  {
    name: "Беларусь",
    iso: "by",
  },
  {
    name: "Великобритания",
    iso: "gb",
  },
  {
    name: "Германия",
    iso: "de",
  },
];

export const DEFAULT_COUNTRY_ISO = "ru";

export const COUNTRIES_POOL: CountryProps[] = COUNTRIES_PHONE_MASKS
  .filter((filteredCountry: CountryProps) =>
    selectablesCountries.some((selectableCountry) =>
      filteredCountry.iso.toLowerCase() === selectableCountry.iso))
  .map((countryItem: CountryProps) =>
    ({
      ...countryItem,
      name: selectablesCountries.find((item) =>
        item.iso === countryItem.iso.toLowerCase())?.name,
    }));
