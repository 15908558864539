module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Lunaro","name":"Lunaro","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","orientation":"portrait","start_url":"/catalog","scope":"https://lunaro.ru/","id":"https://lunaro.ru/","related_applications":[],"icon":"src/images/favicon.svg","icons":[{"src":"/favicons/48.png?v=2","sizes":"48x48","type":"image/png"},{"src":"/favicons/72.png?v=2","sizes":"72x72","type":"image/png"},{"src":"/favicons/96.png?v=2","sizes":"96x96","type":"image/png"},{"src":"/favicons/144.png?v=2","sizes":"144x144","type":"image/png"},{"src":"/favicons/180.png?v=2","sizes":"180x180","type":"image/png"},{"src":"/favicons/192.png?v=2","sizes":"192x192","type":"image/png"},{"src":"/favicons/256.png?v=2","sizes":"256x256","type":"image/png"},{"src":"/favicons/384.png?v=2","sizes":"384x384","type":"image/png"},{"src":"/favicons/512.png?v=2","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"df976aaf439dd2062be730c5ac2da8a6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/foretellfront/foretellfront/src/components/layout/index.tsx"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
