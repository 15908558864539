import { useCallback, useEffect, useState } from "react";

import { HistoryActionType, globalHistory } from "@reach/router";

import { WebsiteSectionUrl } from "@/components/constants";
import { isBrowser } from "@/utils/env";

import { ScrollMapType, UseScrollManagementProps } from "./types";

/**
 * Provides methods to scroll top or to the saved position while user browses pages
 * @returns
 */
const useScrollManagement = (props: UseScrollManagementProps) => {
  const { pathsToPreservePosition, renderedContentPath } = props;

  const [globalHistoryAction, setGlobalHistoryAction] = useState<HistoryActionType | null>(null);
  const [currentPathname, setCurrentPathname] = useState<string>(isBrowser() ? window.location.pathname : "");
  const [preservedPositionPaths] = useState<WebsiteSectionUrl[]>(pathsToPreservePosition);
  const [preservedPositionScrollMap, setPreservedPositionScrollMap] = useState<ScrollMapType>({});

  useEffect(() => {
    const unsubscribeFunction = globalHistory.listen(({ location, action }) => {
      setGlobalHistoryAction(action);
      setCurrentPathname(location.pathname + location.search);
    });
    return unsubscribeFunction;
  }, []);

  const scrollCallback = useCallback((e: Event) => {
    const currentPageMapKey = preservedPositionPaths.find((preservedPositionPage) =>
      currentPathname.startsWith(`/${preservedPositionPage}`));
    if (currentPageMapKey) {
      const newScrollPosition = (e.target as HTMLElement).scrollTop;

      setPreservedPositionScrollMap((oldVal) =>
        (oldVal[currentPathname] === newScrollPosition
          ? oldVal
          : { ...oldVal, [currentPathname]: newScrollPosition }));
    }
  }, [currentPathname, preservedPositionPaths]);

  useEffect(() => {
    const currentPageMapKey = preservedPositionPaths.find((preservedPositionPage) =>
      currentPathname.startsWith(`/${preservedPositionPage}`));

    if (currentPageMapKey) {
      const { body } = document;

      body.addEventListener("scroll", scrollCallback);

      return () => {
        body.removeEventListener("scroll", scrollCallback);
      };
    }
  }, [currentPathname, preservedPositionPaths, scrollCallback, globalHistoryAction]);

  useEffect(() => {
    const currentPageMapKey = Object.keys(preservedPositionScrollMap)
      .find((preservedPositionPage) =>
        currentPathname === preservedPositionPage);

    // If user comes to the current page via browser back button
    // and the page has been rendered and has saved scroll position
    if (globalHistoryAction === "POP" && currentPageMapKey === renderedContentPath) {
      setGlobalHistoryAction(null);
      document.body.scrollTo(0, preservedPositionScrollMap[currentPathname]);
    }
  }, [globalHistoryAction, currentPathname, preservedPositionScrollMap, renderedContentPath]);

  useEffect(() => {
    const currentPageMapKey = preservedPositionPaths
      .find((preservedPositionPage) =>
        currentPathname.startsWith(`/${preservedPositionPage}`));

    // If user browses website normally
    // or if he comes to the page via browser back button which hasn't saved scroll position
    if (globalHistoryAction === "PUSH" || (!currentPageMapKey && currentPathname === renderedContentPath)) {
      document.body.scrollTo(0, 0);
    }
  }, [globalHistoryAction, currentPathname, preservedPositionPaths, renderedContentPath]);

  return { };
};

export default useScrollManagement;
