import { gql } from "@apollo/client";

export const GET_PROFILE_QUERY = gql`
  query getMyProfile {
    getMyProfile {
      user {
        id
        freeMinutesLeft
      }
      balance {
        amount
        currency
      }
      isExpert
    }
  }
`;
