import React from "react";

import "./styles.scss";
import { LoaderProps } from "./types";

const Loader = (props: LoaderProps) => {
  const { ...rest } = props;

  return (
    <div {...rest} className="loader__container">
      <div className="loader">
        <div className="loader__loading">
          <div className="loader__spinner" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
