import React from "react";

import LogoPath from "@images/logo_full.svg";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import { reviewPagePath } from "@/components/constants";

import { LogoProps } from "./types";

const Logo = ({ className }: LogoProps) => {
  const location = useLocation();
  const redirectFromReviewPage = location.pathname === reviewPagePath;

  return (
    <Link to="/" className={className} state={{ showBannerFromFlocktory: !!redirectFromReviewPage }}>
      <img src={LogoPath} alt="logo" width="128" height="24" />
    </Link>
  );
};

export default Logo;
