import { gql } from "@apollo/client";

export const SAVE_UTM = gql`
  mutation saveUtm(
    $utm: String!
    $eventType: String!
    $sessionID: Int
    $paymentID: Int
  ) {
    saveUtm(
      input: {
        utm: $utm
        eventType: $eventType
        sessionID: $sessionID
        paymentID: $paymentID
      }
    )
  }
`;
