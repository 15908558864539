import { gql } from "@apollo/client";

export const GET_USER_QUESTIONNAIRE_INFO = gql`
  query getUserQuestionnaireInfo {
    getMyProfile {
      user {
        name
        dateOfBirth
        freeMinutesLeft
      }
      email
      isEmailVerified
      phone
    }
  }
`;
