import { IconTypeEnum } from "@/components/common/icon";

export const MENU_ITEMS = [
  {
    link: `/profile/balance/`,
    text: "Баланс\u00A0и\u00A0платежи",
    icon: IconTypeEnum.Payment,
  },
  {
    link: `/profile/sessions/`,
    text: "Мои\u00A0консультации",
    icon: IconTypeEnum.VideoOn,
  },
  {
    link: `/profile/account/`,
    text: "Аккаунт",
    icon: IconTypeEnum.Profile,
  },
  {
    link: `/profile/referral-link/`,
    text: "Бесплатные минуты за друга",
    subTitle: `Приглашайте друзей и получайте\nпо 10 минут за каждого`,
    icon: IconTypeEnum.Invitation,
    indicator: true,
  },
  {
    link: `/profile/promo-codes/`,
    text: "Промокоды",
    icon: IconTypeEnum.Tickets,
  },
  {
    link: `/landing/gift-card`,
    text: "Подарочные сертификаты",
    icon: IconTypeEnum.Certificate,
  },
];
