/** Website sections URLs */
export enum WebsiteSectionUrl {
  Catalog = "catalog",
  Article = "articles",
  Category = "category",
  Goroskop = "goroskop",
  Gid = "gid",
}

export enum UrlParamKeyEnum {
  LoginFormModal = "login_form",
  EmailConfirmation = "confirm_email",
  AccountModal = "account_modal",
}

export const VALID_SESSION_LENGTH_MIN = 2;

export const CATALOG_PAGE_NAME = "catalog"; // todo: replace with WebsiteSectionUrl.Catalog and delete

/** Minimum amount user can pay via payment form */
export const MIN_PAYMENT_AMOUNT = 99;

export const paymentTexts = {
  rememberText: "Запомнить карту, чтобы\u00a0не\u00a0вводить каждый раз",
  message:
    "А ещё вы сможете докупить минуты и\u00a0продлить консультацию, если времени не\u00a0хватит",
  protectionInfo:
    "Зашифровано по\u00a0международному стандарту безопасности PCI\u00a0DSS. Не\u00a0храним CVC/CVV-коды",
};

export const UNSPECIFIED_RATE_GAG_RUB = 59;

export const SEND_OTP_CODE_RETRY_DELAY = 60000;

export const reviewPagePath = "/chat/review";
