import { InputErrorMessagesEnum } from "./types";

const getMinAdultsBirthDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const getStringDateError = (
  dateToValidate: string,
  isUnderageAllowed: boolean,
): InputErrorMessagesEnum|null => {
  if (dateToValidate.match("_")) {
    return InputErrorMessagesEnum.InvalidFormat;
  }
  if (!isUnderageAllowed) {
    const match = dateToValidate.match(/(\d{2})\.(\d{2})\.(\d{4})$/);
    if (match?.length === 4) {
      const matchedDay = parseInt(match[1]!, 10);
      const matchedMonth = parseInt(match[2]!, 10);
      const matchedYear = parseInt(match[3]!, 10);

      if (new Date(matchedYear, matchedMonth - 1, matchedDay) >= getMinAdultsBirthDate()) {
        return InputErrorMessagesEnum.InvalidAge;
      }
    }
  }
  return null;
};
