import React from "react";

import { Icon, IconTypeEnum } from "@components/common/icon";

import { UserAvatarProps } from "./types";
import "./styles.scss";

export const UserAvatar = ({
  mobileImage,
  desktopImage,
  name,
  // userId,
  type,
  // email,
}: UserAvatarProps) => {
  if (mobileImage?.onex && desktopImage?.onex) {
    return (
      <picture className={`user-avatar user-avatar--${type ?? "default"}`}>
        <source
          srcSet={`${mobileImage.onex},
              ${mobileImage.twox} 2x,
              ${mobileImage.threex} 3x`}
          media="(max-width: 767px)"
        />
        <source
          srcSet={`${desktopImage.onex},
              ${desktopImage.twox} 2x,
              ${desktopImage.threex} 3x`}
          media="(min-width: 768px)"
        />
        <img src={mobileImage.twox || ""} alt={name || ""} width="176" height="176" />
      </picture>
    );
  }

  // todo: uncomment when there is empty user name by default. Now there isn't
  // if (!!name || !!email) {
  //   return (
  //     <div className={`user-avatar user-avatar--${(userId % 3) + 1} user-avatar--${type ?? "default"}`}>
  //       {!!name ? convertTextToIcon(name) : convertTextToIcon(email ?? "")}
  //     </div>
  //   );
  // }

  return (
    <div className={`user-avatar user-avatar--empty user-avatar--${type ?? "default"}`}>
      <Icon type={IconTypeEnum.User} />
    </div>
  );
};
