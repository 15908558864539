import { createContext } from "react";

import { FormTypeEnum, LoginTypeEnum } from "@layout/modals/types";

import { AuthorizationFormContextType } from "./types";

const AuthorizationFormContext = createContext<AuthorizationFormContextType>({
  formType: null,
  setFormType: () => {},
  loginType: LoginTypeEnum.LoginByPhone,
  setLoginType: () => {},
  backButtonFormType: FormTypeEnum.Login,
  setBackButtonFormType: () => {},
  formCloseCallback: null,
  setFormCloseCallback: () =>
    () =>
      null,
  sentCodeTimestamp: null,
  setSentCodeTimestamp: () => {},
});

export default AuthorizationFormContext;
