import React, { useCallback, useContext } from "react";

import { useMutation } from "@apollo/client";

import UserContext from "@/contexts/User/UserContext";
import { isBrowser } from "@/utils/env";

import { saveReferer, saveRefererVariables } from "./graphql/__generated__/saveReferer";
import { saveUtm, saveUtmVariables } from "./graphql/__generated__/saveUtm";
import { SAVE_REFERER } from "./graphql/SAVE_REFERER";
import { SAVE_UTM } from "./graphql/SAVE_UTM";
import {
  UTM_DATA_SESSION_KEY,
  UseManageUtmDataType,
  UtmAnalysisEventEnum,
  UtmDataType,
} from "./types";

/**
 * Returns functions for storing UTM and other marketing data in session and saving it to backend
 */
const useManageUtmData = (): UseManageUtmDataType => {
  const [saveUtmMutation] = useMutation<saveUtm, saveUtmVariables>(SAVE_UTM);
  const [saveRefererMutation] = useMutation<saveReferer, saveRefererVariables>(SAVE_REFERER);
  const { clientReferrer } = useContext(UserContext);

  const storeUtmData = useCallback(() => {
    if (!isBrowser()) {
      return;
    }

    const utmData = window.location.search.replace(/^\?/, "").replace(/amp;/g, ""); // replace(/amp;/g, ""); Удаляем лишние символы из UTM после платежа через Softline.

    if (utmData) {
      sessionStorage.setItem(UTM_DATA_SESSION_KEY, utmData);
    } else {
      sessionStorage.removeItem(UTM_DATA_SESSION_KEY);
    }
  }, []);

  const getUtmData = useCallback(() => {
    if (!isBrowser()) {
      return null;
    }
    return sessionStorage.getItem(UTM_DATA_SESSION_KEY);
  }, []);

  const saveUtmData = useCallback(
    ({ eventType, sessionID = 0, paymentID = 0 }: UtmDataType) => {
      if (!isBrowser()) {
        return;
      }

      const utmData = sessionStorage.getItem(UTM_DATA_SESSION_KEY) || "";
      if (utmData) {
        saveUtmMutation({
          variables: {
            utm: utmData,
            eventType,
            sessionID,
            paymentID,
          },
        });
      }
      if (clientReferrer) {
        saveRefererMutation({
          variables: {
            input: {
              referer: clientReferrer,
              eventType,
              paymentID,
              sessionID,
            },
          },
        });
      }
    },
    [clientReferrer, saveRefererMutation, saveUtmMutation],
  );

  return {
    storeUtmData,
    getUtmData,
    saveUtmData,
  };
};

export { useManageUtmData, UtmAnalysisEventEnum };
