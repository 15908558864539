import { MutableRefObject, useEffect, useRef } from "react";

const useOutsideClick = (
  callback: () => void,
  eventList: string,
  exceptionRefs?: MutableRefObject<HTMLDivElement | null>[],
) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleEvent = (event: { target: HTMLElement }) => {
      let isException = false;
      exceptionRefs?.forEach((exceptionRef) => {
        if (exceptionRef?.current && exceptionRef.current.contains(event.target)) {
          isException = true;
        }
      });
      if (!isException && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    // todo: может не стоит каждый раз обрабатывать событие?
    // Их может быть оч много Think about
    eventList.split(" ")
      .forEach((eventType: any) => {
        document.addEventListener(eventType, handleEvent);
      });

    // todo: can create troubles. Better move to the empty useEffect
    return () => {
      eventList.split(" ")
        .forEach((eventType: any) => {
          document.removeEventListener(eventType, handleEvent);
        });
    };
  }, [callback, eventList, exceptionRefs, ref]);

  if (!eventList.length && !Array.isArray(eventList)) {
    return ref;
  }

  return ref;
};

export default useOutsideClick;
