import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  InMemoryCache,
  Observable,
  Operation,
  from,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { captureException } from "@sentry/gatsby";
import { createUploadLink } from "apollo-upload-client";
import fetch from "isomorphic-fetch";

import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
  triggerAuthorizationForm,
} from "@/account/user";
import { DEVICE_UUID, MINDBOX_DEVICE_ID } from "@/constants";
import { getCookieValue } from "@/utils/commonUtils";

const REFRESH_TOKEN = `
  mutation refreshToken($refreshToken: String!) {
    refreshToken(input: {refreshToken: $refreshToken}) {
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;

const retryLink = new RetryLink({
  attempts: (count: number, operation: Operation, error: any) => {
    const isMutation = operation
      && operation.query
      && operation.query.definitions
      && Array.isArray(operation.query.definitions)
      && operation.query.definitions.some(
        (def) =>
          def.kind === "OperationDefinition" && def.operation === "mutation",
      );

    // Retry mutations for a looong time, those are very important to us,
    // so we want them to go through eventually
    if (isMutation) {
      return !!error;
    }

    // Retry queries for way less long as this just ends up showing
    // loading indicators for that whole time which is v annoying
    return !!error;
  },
  delay: {
    initial: 300,
    max: Infinity,
  },
});

const httpLink = retryLink.concat(
  createUploadLink({
    uri: `${process.env.GATSBY_API_URL}query`,
    fetch: (uri: RequestInfo, options: RequestInit | undefined) =>
      fetch(uri, options).catch((error) => {
        throw new Error(JSON.parse(error));
      }),
  }),
);

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].extensions.code === "2") {
      return updateToken(forward, operation);
    }

    graphQLErrors.forEach(({ message, extensions, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      captureException(new Error(message));
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    captureException(networkError);
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken();
  const mindboxDeviceUUID = getCookieValue(MINDBOX_DEVICE_ID);
  const deviceUUID = getCookieValue(DEVICE_UUID);

  operation.setContext(({ headers = {} }) =>
    ({
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
        "Device-uuid": mindboxDeviceUUID || deviceUUID || "",
      },
    }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([authMiddleware, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

function updateToken(
  forward: {
    (operation: Operation): Observable<FetchResult>;
    (operation: Operation): Observable<FetchResult>;
    (arg0: any): any;
  },
  operation: Operation,
) {
  return fromPromise(
    fetch(`${process.env.GATSBY_API_URL}query`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        query: REFRESH_TOKEN,
        variables: {
          refreshToken: getRefreshToken(),
        },
      }),
    })
      .then((res) =>
        res.json())
      .then((result) => {
        const { accessToken = "", refreshToken = "" } = result.data
          ? result.data.refreshToken.tokens
          : {};

        accessToken ? setAccessToken(accessToken) : removeAccessToken();
        refreshToken ? setRefreshToken(refreshToken) : removeRefreshToken();

        !refreshToken && triggerAuthorizationForm();

        const { cache } = client;
        cache.reset();
      }),
  ).flatMap(() =>
    retryFetch(forward, operation));
}

function retryFetch(
  forward: {
    (operation: Operation): Observable<FetchResult>;
    (arg0: any): any;
  },
  operation: Operation,
) {
  const oldHeaders = operation.getContext().headers;
  operation.setContext({
    headers: {
      ...oldHeaders,
      Authorization: getAccessToken() ? `Bearer ${getAccessToken()}` : null,
    },
  });

  return forward(operation);
}

export default client;
