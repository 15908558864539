import * as React from "react";

import { IconProps, IconSizeEnum, IconTypeEnum } from "./types";
import getIconPath from "./utils";

export const Icon = (props: IconProps) => {
  const { type, size = IconSizeEnum.Size32, viewBox, title, desc, ...rest } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox ? `0 0 ${viewBox} ${viewBox}` : "0 0 32 32"}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...rest}
    >
      {title && <span>{title}</span>}
      {desc && <desc>{desc}</desc>}
      {getIconPath(type)}
    </svg>
  );
};

export { IconTypeEnum, IconSizeEnum };
