const REDIRECT_URL = "redirect";

export const getRedirectUrl = () =>
  localStorage.getItem(REDIRECT_URL);

export const setRedirectUrl = (value: string) =>
  localStorage.setItem(REDIRECT_URL, value);

export const removeRedirectUrl = () =>
  localStorage.removeItem(REDIRECT_URL);

export const getRedirectUrlSessionStorage = () =>
  sessionStorage.getItem(REDIRECT_URL);

export const setRedirectUrlSessionStorage = (value: string) =>
  sessionStorage.setItem(REDIRECT_URL, value);

export const removeRedirectUrlSessionStorage = () =>
  sessionStorage.removeItem(REDIRECT_URL);
