import React, { useEffect, useRef, useState } from "react";

import { secondsToTime } from "@/utils/numberUtils";
import { calculateTimerValue } from "@components/chat/Duration/utils";
import { TimerProps } from "@components/common/Timer/types";

/**
 * Component for counting seconds
 * @param timestamp
 * @param isPaused
 * @param valueCallback
 * @return
 * @deprecated Use TimerFixed instead
 */
const Timer = ({ timestamp = Date.now(), isPaused = false, valueCallback }: TimerProps) => {
  const [currentTimeValue, setCurrentTimeValue] = useState<number>(calculateTimerValue(timestamp));
  const sessionDurationTimer = useRef<NodeJS.Timer | null>(null);

  function startTimer() {
    if (sessionDurationTimer.current === null) {
      sessionDurationTimer.current = setInterval(() => {
        setCurrentTimeValue((oldValue) => {
          oldValue === -1 && clearTimer();
          return oldValue + 1;
        });
      }, 1000);
    }
  }

  function clearTimer() {
    if (sessionDurationTimer.current !== null) {
      clearInterval(sessionDurationTimer.current);
      sessionDurationTimer.current = null;
    }
  }

  useEffect(() => {
    clearTimer();
    startTimer();
    setCurrentTimeValue(calculateTimerValue(timestamp));
  }, [startTimer, timestamp]);

  useEffect(() => {
    if (isPaused) {
      clearTimer();
    } else {
      startTimer();
    }
  }, [isPaused, startTimer]);

  useEffect(() => {
    if (valueCallback) {
      valueCallback(currentTimeValue);
    }
  }, [currentTimeValue, valueCallback]);

  useEffect(
    () =>
      () => {
        clearTimer();
      },
    [],
  );

  return <>{secondsToTime(Math.abs(currentTimeValue))}</>;
};

export default Timer;
