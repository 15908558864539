import React from "react";

import { Link } from "gatsby";

import { Icon, IconSizeEnum } from "@components/common/icon";

import { data } from "./constants";
import { SupportProps } from "./props";
import "./styles.scss";

const Support = ({ isShort }: SupportProps) =>
  (
    <div className={`support${isShort ? " is-short" : ""}`}>
      <h4 className="h4">{data.title}</h4>
      <div className="support__top">
        {!isShort && (
        <Link title={data.qa.title} to={data.qa.link}>
          <Icon type={data.qa.icon} size={IconSizeEnum.Size24} />
          <span>{data.qa.title}</span>
        </Link>
        )}
        <a title={data.mail.title} href={`mailto:${data.mail.link}`}>
          <Icon type={data.mail.icon} size={IconSizeEnum.Size24} />
          <span>{data.mail.title}</span>
        </a>
      </div>
      <div className="support__bottom">
        <a title={data.wa.title} href={data.wa.link} target="_blank" rel="noreferrer">
          <Icon type={data.wa.icon} size={IconSizeEnum.Size24} />
          <span>{data.wa.title}</span>
        </a>
        <a title={data.tg.title} href={data.tg.link} className="support__social-item--tg" target="_blank" rel="noreferrer">
          <Icon type={data.tg.icon} size={IconSizeEnum.Size24} />
          <span>{data.tg.title}</span>
        </a>
      </div>
    </div>
  );

export default Support;
