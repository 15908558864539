import React from "react";

import { DigitCodeInputProps } from "./types";
import { codeInputHelper, convertToNumberOrReplace } from "./utils";
import "./styles.scss";

const DigitCodeInput = (props: DigitCodeInputProps) => {
  const {
    code,
    setCode,
    disabled,
    firstCodeInputRef,
  } = props;

  const setCodeHandler = (character: string | number, index: number) => {
    setCode(
      (prevState) =>
        prevState.substring(0, index)
        + convertToNumberOrReplace(character, "_")
        + prevState.substring(index + 1),
    );
  };

  const pastCodeEvent = (codeString: string) => {
    const clearPastedCode = codeString.replace(/\D/g, "");

    if (clearPastedCode) {
      const str = clearPastedCode.substring(0, 4);
      for (let i = 0; i < str.length; i += 1) {
        setCodeHandler(str[i], i);
      }
    }
  };

  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>, i: number) => {
    const {
      key = "",
      index = i,
    } = codeInputHelper(e, i) || {};

    if (key.length === 1) {
      setCodeHandler(key, index);
    } else {
      pastCodeEvent(key);
    }
  };

  const codeFragmentFillHandler = (e: React.KeyboardEvent<HTMLInputElement>, i: number) => {
    // if we paste copying data
    // or Android troubles with keyPress return 229
    if (e.metaKey || e.ctrlKey || e.keyCode === 229) {
      return;
    }

    e.preventDefault();
    const {
      key = "",
      index = i,
    } = codeInputHelper(e, i) || {};
    setCodeHandler(key, index);
  };

  const onPasteHandler = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    pastCodeEvent(e.clipboardData?.getData("Text"));
  };

  return (
    <div className="digit-code-input__container">
      {code.split("").map((fragment, index) =>
        (
          <input
            type="number"
            className="digit-code-input"
            inputMode="numeric"
            ref={index === 0 ? firstCodeInputRef : null}
            maxLength={1}
            onChange={(e) =>
              handleChange(e, index)}
            onKeyDown={(e) =>
              codeFragmentFillHandler(e, index)}
            onPaste={(e) =>
              onPasteHandler(e)}
            value={convertToNumberOrReplace(fragment)}
            key={index}
            disabled={disabled}
          />
        ))}
    </div>
  );
};

export default DigitCodeInput;
