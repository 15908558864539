import { IconSizeEnum } from "../icon";

import { ButtonColorEnum, ButtonSizeEnum } from "./types";

export const getButtonClassName = (
  size: ButtonSizeEnum,
  color: ButtonColorEnum,
  appearsAsLink: boolean,
  className?: string,
): string =>
  `${size.toLowerCase()} ${color.toLowerCase()} ${appearsAsLink ? "link" : ""}${className ? ` ${className}` : ""}`;

export const getIconSize = (size: ButtonSizeEnum): IconSizeEnum | null => {
  switch (size) {
    case ButtonSizeEnum.Large:
      return IconSizeEnum.Size24;
    case ButtonSizeEnum.Middle:
      return IconSizeEnum.Size20;
    case ButtonSizeEnum.Small:
      return IconSizeEnum.Size20;
    case ButtonSizeEnum.XSmall:
      return IconSizeEnum.Size16;
  }
};

export const getLoadingIconSize = (size: ButtonSizeEnum): IconSizeEnum | null => {
  switch (size) {
    case ButtonSizeEnum.Large:
      return IconSizeEnum.Size32;
    case ButtonSizeEnum.Middle:
      return IconSizeEnum.Size24;
    case ButtonSizeEnum.Small:
      return IconSizeEnum.Size24;
    case ButtonSizeEnum.XSmall:
      return IconSizeEnum.Size24;
    default:
      return null;
  }
};
