export enum UserAvatarTypeEnum {
  Header = "header",
  HeaderSmall = "header-small",
  Catalog = "catalog",
  Call = "call",
  CallSmall = "call-small",
  Appointment = "appointment",
  Profile = "profile",
  Stories = "stories",
  Chat = "chat",
}

export type UserAvatarProps = {
  mobileImage?: {
    onex: string,
    twox: string,
    threex: string,
  },
  desktopImage?: {
    onex: string,
    twox: string,
    threex: string,
  }
  name?: string;
  // userId: number;
  // email?: string;
  type?: UserAvatarTypeEnum;
};
