import { gql } from "@apollo/client";

export const GET_USER_BALANCE = gql`
  query getUserBalance {
    getMyProfile {
      balance {
        amount
        currency
      }
    }
  }
`;
