import { gql } from "@apollo/client";

export const AUTHORIZE_MUTATION = gql`
  mutation authorize(
    $phone: String,
    $email: String,
    $utm: String
    $deviceID: String,
    $code: String,
    $password: String,
    $referral: String
  ) {
    authorize(
      input: {
        user: { email: $email, phone: $phone, utm: $utm, deviceID: $deviceID, referral: $referral }
        pass: { code: $code, password: $password }
      }
    ) {
      isFirstAuth
      userID
      userUUID
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;
