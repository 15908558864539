import * as Sentry from "@sentry/gatsby";

import { ExpertState, SessionStatus, SessionType } from "@/autoGeneratedGlobalTypes";
import { MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL, MIN_SESSION_DURATION } from "@/constants";
import { getExperts_getExperts_edges } from "@/components/catalog/graphql/__generated__/getExperts";
import { GetConstructorExperts_getExperts_edges } from "@/components/textPages/constructor/graphql/__generated__/GetConstructorExperts";

export const isNullOrUndefined = <T>(v: T | null | undefined): v is T =>
  typeof v === "undefined" || v === null;

export const isNotNullOrUndefined = <T>(v: T | null | undefined): v is T =>
  !isNullOrUndefined(v);

/**
 * Проверяет авторизацию и бесплатные минуты консультации, мин больше или 5
 */
export const isLoggedInAndHasFreeMinutes = (
  isUserLoggedIn: boolean,
  freeMinutesCount: number | null,
) => {
  if (!isUserLoggedIn) {
    return true;
  }
  if (isUserLoggedIn
    && freeMinutesCount
    && freeMinutesCount >= MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL) {
    return true;
  }
  if (isUserLoggedIn
    && freeMinutesCount
    && freeMinutesCount < MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL) {
    return false;
  }
  return false;
};

// todo: maybe delete GATSBY_IS_NO_FIRST_PAYMENT_ACTION_ACTIVE action
/**
 * Return flag indicating if no firsh payment action is active for user
 * @param isUserLoggedIn
 * @param freeMinutesCount
 * @returns
 */
export const isActionActiveForUser = (
  isUserLoggedIn: boolean,
  freeMinutesCount: number | null,
) =>
  process.env.GATSBY_IS_NO_FIRST_PAYMENT_ACTION_ACTIVE === "true"
  && (isUserLoggedIn
    ? (freeMinutesCount ?? 0) >= MIN_SESSION_DURATION
    : parseInt(process.env.GATSBY_FREE_MINUTES_COUNT ?? "0", 10));

/**
 * Return flag indicating if user can call to expert
 * @param expertState
 * @param alertStatus
 * @returns
 */
export const canUserCall = (
  expertState: ExpertState,
  alertStatus: SessionStatus,
) =>
  expertState === ExpertState.available && alertStatus !== SessionStatus.in_progress;

/**
 * Return flag indicating if type of call is available for expert
 * @param rate
 * @returns
 */
export const isCallTypeAvailable = (
  rate: number | null,
) =>
  rate !== -1
  && rate !== null;

/**
 * Returns amount of free minutes available for authorized or non-authorized user
 * @param isUserLoggedIn
 * @param freeMinutesCount
 * @returns
 */
export const getFreeMinutesForUser = (isUserLoggedIn: boolean, freeMinutesCount: number | null) =>
  (isUserLoggedIn ? freeMinutesCount ?? 0 : parseInt(process.env.GATSBY_FREE_MINUTES_COUNT ?? "0", 10));

/**
 * Returns flag indicating is user should be redirected to session without payment
 * @param freeMinutesCount
 * @returns
 */
export const isUserEligibleForImmediateCall = (
  sessionType: SessionType,
  freeMinutesCount: number | null,
  expertHasTrial: boolean,
) => {
  if (!expertHasTrial) {
    return false;
  }
  if (
    sessionType === SessionType.VIDEO_CALL
    && !expertHasTrial
  ) {
    return false;
  }
  if (
    sessionType === SessionType.PHONE_CALL
  ) {
    return false;
  }
  if (
    sessionType === SessionType.CHAT // todo: edit according to BE
    && !expertHasTrial
  ) {
    return false;
  }

  // Counting if this is the first session for user
  return (
    isNullOrUndefined(freeMinutesCount)
      ? Number(process.env.GATSBY_FREE_MINUTES_COUNT) || 0
      : freeMinutesCount || 0
  ) >= MIN_FREE_MINUTES_COUNT_FOR_IMMEDIATE_CALL;
};

/**
 * Retry Promise if u need it
 * @param requestFn
 * @param options
 */
export function retryPromise<T>(requestFn: () => Promise<T>, options?: {
    retries?: number | "INFINITELY";
    interval?: number;
  }): Promise<T> {
  const {
    retries = 10,
    interval = 1000,
  } = options || {};

  return new Promise((resolve, reject) => {
    let attempts = 0;

    function makeRequest() {
      attempts = +1;

      requestFn()
        .then(resolve)
        .catch((error) => {
          if (retries === "INFINITELY" || attempts < retries) {
            setTimeout(makeRequest, interval);
          } else {
            reject(error);
          }
        });
    }

    makeRequest();
  });
}

/**
 * Makes console.log only for non-prod environment
 * @param args
 */
export function logToConsole(...args: any[]) {
  if (process.env.ENV !== "prod") {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

/**
 * Captures exception to Sentry with specified occurence tag
 * @param args
 */
export function captureException(exception: Error, occurrence: string) {
  Sentry.captureException(exception, { tags: { occurrence } });
}

export const getCookieValue = (name: string) =>
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || "";


  export const sortExpertEdgesByStatus = (
      edges: (getExperts_getExperts_edges | GetConstructorExperts_getExperts_edges | null)[],
      sorting = 0,
    ) => {
    const sortedExperts = [...edges] || [];
    sortedExperts.sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      if (a.node.state === b.node.state) {
        switch (sorting) { //todo: check if sorting is redundund and delete if yes
          case 1:
            return a.node.rates.videoCall - b.node.rates.videoCall;
          case 2:
            return b.node.rates.videoCall - a.node.rates.videoCall;
          default:
            return b.node.rating - a.node.rating;
        }
      } else {
        return a.node.state === "offline" ? 1 : a.node.state === "busy" && b.node.state === "available" ? 1 : -1;
      }
    });
    return sortedExperts;
  }