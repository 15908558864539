export enum UtmAnalysisEventEnum {
  Registration = "Registration",
  PaymentSucceded = "PaymentSucceded",
  CallStarted = "CallStarted",
  PhoneCallStarted = "PhoneCallStarted",
}

export type UtmDataType = {
  eventType: UtmAnalysisEventEnum,
  sessionID?: number,
  paymentID?: number
};

export const UTM_DATA_SESSION_KEY = "udata";

export type UseManageUtmDataType = {
  storeUtmData: () => void;
  getUtmData: () => string | null;
  saveUtmData: (data: UtmDataType) => void;
};
