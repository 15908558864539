import {
  useCallback, useContext, useEffect, useState,
} from "react";

import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { FormTypeEnum } from "@layout/modals/types";
import { convertDateForFront, setAuthParamToURL } from "@layout/modals/utils";
import { navigate } from "gatsby";

import { getRedirectUrlSessionStorage, setRedirectUrlSessionStorage } from "@/account/redirect";
import { setAccessToken, setRefreshToken } from "@/account/user";
import UserContext from "@/contexts/User/UserContext";
import { marketingUserFilledEmailForTheFirstTimeHandler, marketingUserFirstLogin } from "@/marketing/utils";

import { AuthTypeEnum } from "./constants";
import { authorize as authorizeType, authorizeVariables } from "./graphql/__generated__/authorize";
import { authorizeByToken as authorizeByTokenType, authorizeByTokenVariables } from "./graphql/__generated__/authorizeByToken";
import { getUserQuestionnaireInfo } from "./graphql/__generated__/getUserQuestionnaireInfo";
import { AUTHORIZE_MUTATION } from "./graphql/AUTHORIZE";
import { AUTHORIZE_BY_TOKEN } from "./graphql/AUTHORIZE_BY_TOKEN";
import { GET_USER_QUESTIONNAIRE_INFO } from "./graphql/GET_USER_QUESTIONNAIRE_INFO";

const useAuthorize = (
  onSuccess: () => void,
  onError: (authError: ApolloError) => void,
) => {
  const {
    setUserID,
    setEmail,
    setIsEmailVerified,
    setPhone,
    setName,
    setDateOfBirth,
    promoUnauthorizetUser,
  } = useContext(UserContext);
  const [isFirstAuthorization, setIsFirstAuthorization] = useState(false);
  const [authType, setAuthType] = useState<AuthTypeEnum | null>(null);

  const [authorizeUser, {
    data: authData,
    loading: authLoading,
    error: authError,
  }] = useMutation<
    authorizeType,
    authorizeVariables
  >(AUTHORIZE_MUTATION);
  const [authorizeByTokenMutation, {
    data: authByTokenData,
    loading: authByTokenLoading,
    error: authByTokenError,
  }] = useMutation<authorizeByTokenType, authorizeByTokenVariables>(AUTHORIZE_BY_TOKEN);
  const [
    getUserQuestionnaireInfoQuery,
    {
      data: questionnaireInfo,
      loading: questionnaireInfoLoading,
      error: questionnaireInfoError,
    },
  ] = useLazyQuery<getUserQuestionnaireInfo>(
    GET_USER_QUESTIONNAIRE_INFO,
  );

  const authorize = useCallback((loginInfo: authorizeVariables) => {
    if (loginInfo) {
      switch (true) {
        case !!loginInfo.email: setAuthType(AuthTypeEnum.Email); break;
        case !!loginInfo.phone: setAuthType(AuthTypeEnum.Phone); break;
        default: setAuthType(null); break;
      }

      authorizeUser({ variables: loginInfo });
    } else {
      navigate("/catalog");
    }
  }, [authorizeUser]);

  const authorizeByToken = useCallback((loginInfo: authorizeByTokenVariables) => {
    if (loginInfo) {
      authorizeByTokenMutation({ variables: loginInfo });
    } else {
      navigate("/catalog");
    }
  }, [authorizeByTokenMutation]);

  useEffect(() => {
    if ((authData && !authLoading && !authError)
      || (authByTokenData && !authByTokenLoading && !authByTokenError)
    ) {
      const {
        isFirstAuth,
        userID,
        tokens: {
          accessToken,
          refreshToken,
        },
      } = authData ? authData.authorize : authByTokenData!.authorize;

      if (isFirstAuth) {
        marketingUserFirstLogin({ userId: userID?.toString() });
        setIsFirstAuthorization(true);
        if (authType === AuthTypeEnum.Email) {
          marketingUserFilledEmailForTheFirstTimeHandler();
        }
      }
      setUserID(userID);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      getUserQuestionnaireInfoQuery();
      onSuccess();
    }

    if (authError) {
      onError(authError);
    }
  }, [authByTokenData, authByTokenLoading, authByTokenError, authData, authLoading,
    authError, promoUnauthorizetUser, setUserID, getUserQuestionnaireInfoQuery,
    onSuccess, onError, authType]);

  useEffect(() => {
    if (questionnaireInfo && !questionnaireInfoLoading && !questionnaireInfoError) {
      const {
        isEmailVerified,
        email,
        phone,
        user: {
          name,
          dateOfBirth,
          freeMinutesLeft,
        },
      } = questionnaireInfo.getMyProfile;
      setEmail(email);
      setIsEmailVerified(!!isEmailVerified);
      setPhone(phone || "");
      setName(name);
      setDateOfBirth(convertDateForFront(dateOfBirth));
      // todo: maybe call setFreeMinutesCount(freeMinutesLeft)

      if (email) {
        const redirectUrl = getRedirectUrlSessionStorage() || "";
        if (redirectUrl) {
          setAuthParamToURL(window.location, null);
          navigate(redirectUrl);
          setRedirectUrlSessionStorage("");
        } else if (isFirstAuthorization) {
          // redirecting to Welcome modal for auth by email
          setAuthParamToURL(window.location, FormTypeEnum.Welcome);
        } else {
          setAuthParamToURL(window.location, null);
        }
      } else if (!email && freeMinutesLeft && freeMinutesLeft >= 7) {
        setAuthParamToURL(window.location, FormTypeEnum.Questionnaire);
      } else {
        setAuthParamToURL(window.location, null);
      }
    }
  }, [
    questionnaireInfo,
    questionnaireInfoLoading,
    questionnaireInfoError,
    isFirstAuthorization,
    setEmail,
    setIsEmailVerified,
    setPhone,
    setName,
    setDateOfBirth,
  ]);

  return { authorize, authorizeByToken, loading: authLoading || authByTokenLoading };
};

export default useAuthorize;
