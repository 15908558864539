export enum InputErrorMessagesEnum {
  InvalidFormat = "Неверный формат",
  InvalidAge = "Кажется, вам меньше 18 лет. Извините, но мы консультируем только совершеннолетных",
}

export type BirthDateProps = {
  id: string;
  initialValue: string;
  minDateArea?: Date;
  maxDateArea?: Date;
  isUnderageAllowed?: boolean;
  setDateCallback: (value: string, errorText: string|null) => void;
  enterPressCallback?: () => void;
}
