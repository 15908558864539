import { ApolloError } from "@apollo/client";

import { CardType, Currency, UserLoginType } from "@/autoGeneratedGlobalTypes";

/**
 * Возвращает значок валюты
 * @param currency Валюта
 * @returns Значок валюты
 */
export const currencyToString = (currency: Currency): string => {
  switch (currency) {
    case Currency.EUR:
      return "€";
    case Currency.USD:
      return "$";
    case Currency.RUB:
      return "₽";
    default:
      return "$";
  }
};

/**
 * Возвращает строку с типом карты для отображения
 * @param cardType Валюта
 * @returns Строка с типом карты для отображения
 */
export const cardTypeToString = (cardType: string): string => {
  // todo: cardType should be enum
  switch (cardType) {
    case CardType.Mastercard:
      return "MasterCard";
    case CardType.VISA:
      return "Visa";
    // case "apple":
    //   return "Apple Pay";
    case CardType.Mir:
      return "Мир";
    default:
      return "";
  }
};

/**
 * Возвращает строку с типом логина для отображения
 * @param loginType Тип логина
 * @returns Строка с типом логина для отображения
 */
export const loginTypeToString = (loginType: UserLoginType): string => {
  // todo: cardType should be enum
  switch (loginType) {
    case UserLoginType.facebook:
      return "Facebook";
    case UserLoginType.google:
      return "Google";
    // case "apple":
    //   return "Apple Pay";
    case UserLoginType.vk:
      return "Вконтакте";
    default:
      return "";
  }
};

// todo: convert number[] to enum array when it's ready on backend
export const parseErrors = (error: ApolloError | undefined): number[] => {
  const errorObj = JSON.parse(JSON.stringify(error));
  return errorObj.graphQLErrors.map((errorItem: { extensions: { code: any } }) =>
    parseInt(errorItem.extensions.code, 10));
};
