import { gql } from "@apollo/client";

export const SEND_CODE = gql`
  mutation sendCode(
    $email: String
    $phone: String
    $utm: String
    $reCaptcha: String
    $referer: String
  ) {
    sendCode(
      input: { email: $email, phone: $phone, utm: $utm, reCaptcha: $reCaptcha, referer: $referer }
    )
  }
`;
