import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      userID
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;
