/**
 * Rounds number to 1 decimal place
 * @param num
 * @param placesNumber
 * @returns
 */
export const roundToDecimal = (num: number, placesNumber: number = 1) =>
  Math.round((num + Number.EPSILON) * 10 ** placesNumber) / 10 ** placesNumber;

/**
 * Convert bytes to normal size
 * @param bytes
 * @param separator
 */
export const bytesToSize = (bytes: number, separator: string = " ") => {
  if (bytes === 0) {
    return "n/a";
  }
  const sizes = ["Байт", "КБ", "МБ", "ГБ", "ТБ"];
  const sizeDimension = Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024));
  if (sizeDimension === 0) {
    return `${bytes}${separator}${sizes[sizeDimension]}`;
  }
  return `${(bytes / 1024 ** sizeDimension).toFixed(1)}${separator}${sizes[sizeDimension]}`;
};

export function get2Decimals(number: number | string) {
  return Number(number) < 10 ? `0${Number(number)}` : `${number}`;
}

/**
 * Convert seconds to time
 * @param seconds
 */
export const secondsToTime = (seconds: number = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) % 60;
  const croppedSeconds = seconds % 60;
  const getHours = hours > 0 ? `${get2Decimals(hours)}:` : ``;
  const getMinutes = minutes > 0 ? `${get2Decimals(minutes)}` : `00`;

  return `${getHours}${getMinutes}:${get2Decimals(croppedSeconds)}`;
};

/**
 * Return random number between two numbers
 * @param min
 * @param max
 */
export const generateRandom = (min = 0, max = 100) =>
  Math.floor(Math.random() * (max - min)) + min;

/**
 * Возвращает отформатированную для HTML цену
 * @param price Цена числом
 * @returns Отформатированная цена
 */
export const priceToString = (price: number): string => {
  const parts = price.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

/**
 * Приписывает 0 вначале к числу < 10
 * @param num Число
 * @returns Отформатированное число
 */
export const priceTo2DigitsString = (num: number): string => {
  if (num < 10) {
    return `0${num}`;
  }
  return num.toString();
};

export const isEven = (number: number) =>
  number % 2 === 0;

export const isOdd = (number: number) =>
  Math.abs(number % 2) === 1;
