import React from "react";

import { Link } from "gatsby";

type FooterMenuType = {
  itemsList: {
    title: string;
    link: string;
  }[];
};

const FooterMenu = (props: FooterMenuType) => {
  const { itemsList } = props;
  return (
    <ul className="footer__menu">
      {itemsList?.map((item, index) =>
        (
          <li key={index} className="footer__menu-item">
            <Link title={item.title} to={item.link}>
              {item.title}
            </Link>
          </li>
        ))}
    </ul>
  );
};

export default FooterMenu;
