import React, { useContext } from "react";

import { navigate } from "gatsby";

import UserContext from "@/contexts/User/UserContext";

import { Icon, IconTypeEnum } from "../icon";

import { FreeMinutesDisplayProps } from "./types";

import "./styles.scss";

const FreeMinutesDisplay = ({ withMinutes, iconSize, hidePopUpMenu }: FreeMinutesDisplayProps) => {
  const {
    isUserLoggedIn,
    freeMinutesCount,
  } = useContext(UserContext);

  const navigateHandler = () => {
    if (isUserLoggedIn) {
      navigate("/profile/balance");
    }
    if (hidePopUpMenu) {
      hidePopUpMenu();
    }
  };

  return (
    <div
      className="gift-free-minutes-container"
      onClick={navigateHandler}
    >
      <Icon type={IconTypeEnum.Gift} size={iconSize} />
      <span className="gift-free-minutes-container__text">
        {freeMinutesCount}
        {" "}
        {withMinutes ? <span className="gift-free-minutes-container__minutes"> мин</span> : ""}
      </span>
    </div>
  );
};

export default FreeMinutesDisplay;
