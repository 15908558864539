import { gql } from "@apollo/client";

export const AUTHORIZE_BY_TOKEN = gql`
  mutation authorizeByToken(
    $token: String,
  ) {
    authorize(
      input: {
        pass: { token: $token }
      }
    ) {
      isFirstAuth
      userID
      userUUID
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;
