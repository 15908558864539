import React from "react";

import { CODE_LENGTH } from "./constants";

// todo: move logic inside DigitCodeInput component and pass up via callback
/**
 * Checking if code length valid
 * @param code
 */
export function isCodeLengthValid(code: string) {
  return code.replaceAll("_", "").length === CODE_LENGTH;
}

export function getInitiateCodeState() {
  return "_".repeat(CODE_LENGTH);
}

/**
 * Convert string symbol to the Number
 * @param s
 * @return Number | NaN
 */
export function convertCharacterToNumber(s: string | number) {
  if (typeof s === "number") {
    return s;
  }

  if (!s) {
    return "";
  }

  // exclude " " and "" values
  return s.length ? Number(s.replace(/\s/g, "_")) : NaN;
}

/**
 * Checking the incoming character for conversion to a number, or replace it
 * @param character
 * @param replaceValue
 * @return Number | String
 */
export function convertToNumberOrReplace(character: string | number, replaceValue = "") {
  const value = convertCharacterToNumber(character);
  return Number.isInteger(value) ? value : replaceValue;
}

/**
 * Handling the pressed key to determine what to do next within code inputs
 * @param e
 * @param index
 * @return
 */
export function codeInputHelper(e: React.KeyboardEvent<HTMLInputElement>, index: number) {
  const nextSibling = (e.target as HTMLInputElement).nextElementSibling;
  const prevSibling = (e.target as HTMLInputElement).previousElementSibling;

  switch (true) {
    case e.code === "Backspace":
    case e.code === "Delete":
    case e.code === "NumpadDecimal":
      if ((e.target as HTMLInputElement).value) {
        return {
          key: e.key,
          index,
        };
      }
      if (prevSibling) {
        (prevSibling as HTMLInputElement).focus();
        return {
          key: e.key,
          index: index - 1,
        };
      }
      break;
    case Number.isInteger(convertCharacterToNumber(e.key)):
    case Number.isInteger(convertCharacterToNumber((e.target as HTMLInputElement).value)):
      if (nextSibling) {
        (nextSibling as HTMLInputElement).focus();
      }
      return {
        key: e.key || (e.target as HTMLInputElement).value,
        index,
      };
    default:
  }

  return {};
}
