import React from "react";

import Modal from "@/components/common/modal";
import LoginTroubles from "@/components/modals/supportModal/loginTroubles";

import { SupportModalProps } from "./types";

const SupportModal = ({ urlParamKey }: SupportModalProps) =>
  (
    <Modal
      urlParamKey={urlParamKey}
      header="Проблемы со входом?"
      subtitle="Свяжитесь с нашей Cлужбой поддержки"
      isBackButtonShown
    >
      <LoginTroubles />
    </Modal>
  );

export default SupportModal;
