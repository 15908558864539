import { gql } from "@apollo/client";

export const GET_ACTIVE_SESSION = gql`
  query getActiveSession {
    getActiveSession {
      id
      status
      expert {
        id
        mobileImage: image(size: 120) {
          onex
          twox
          threex
        }
        desktopImage: image(size: 120) {
          onex
          twox
          threex
        }
        name
        skills {
          badge
        }
        rates {
          videoCall
          phoneCall
          chat
        }
        voxImplantInfo {
          userName
        }
      }
      client {
        id
        mobileImage: image(size: 120) {
          onex
          twox
          threex
        }
        desktopImage: image(size: 120) {
          onex
          twox
          threex
        }
        name
        voxImplantInfo {
          userName
        }
      }
      sessionType
      voxConversationID
    }
  }
`;
