export enum DeviceTypeEnum {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
}

export enum BrowserNameEnum {
  Chrome = "chrome",
  Firefox = "firefox",
  Safari = "safari",
  Yandex = "yandex",
  Edge = "edge",
}

export enum OSNameEnum {
  IOS = "iOS",
  Android = "Android OS",
}

export enum CarouselSeoUrlPrefixEnum {
  Page = "",
  Article = "articles",
  Catalog = "catalog",
  Category = "category",
  Goroskop = "goroskop",
}
