import { convertToI18lClearPhone } from "@layout/modals/utils";

import { COUNTRIES_PHONE_MASKS, COUNTRIES_POOL, DEFAULT_COUNTRY_ISO } from "./constants";
import { CountryProps, PhoneMask } from "./types";

export function getCountryMask(country: CountryProps) {
  return Array.isArray(country.mask) ? country.mask[country.testedMaskIndex || 0] : country.mask;
}

export function prettifyMask(mask: string) {
  return mask.replace(")", " ")
    .replace("(", "");
}

/**
 * Clear line from anything, except digits
 * @param value
 */
export function getDigitsOnly(value: string) {
  return value.replace(/\D/g, "");
}

export const getCountryByNumber = (phone: string) => {
  if (!phone) {
    return null;
  }

  return COUNTRIES_PHONE_MASKS.find((item: CountryProps) =>
    phone.startsWith(item.code));
};

export const getNumberWithoutCountryCode = (phone: string) => {
  if (!phone) {
    return phone;
  }

  const countryCode = COUNTRIES_PHONE_MASKS.find((item: CountryProps) =>
    phone.startsWith(item.code))
    ?.code;

  return phone.substring(countryCode.length);
};

export function getDefaultCountry(): CountryProps | undefined {
  return COUNTRIES_POOL
    .find((country: CountryProps) =>
      country.iso.toLowerCase() === DEFAULT_COUNTRY_ISO);
}

/**
 * Workaround for phone lib
 * @returns Mask itself of the first mask from array
 * @param item
 */
function getDefaultMask(item: CountryProps): PhoneMask {
  const {
    mask,
    testedMaskIndex,
  } = item;
  if (Array.isArray(item.mask)) {
    return item.testedMaskIndex ? mask[testedMaskIndex] : mask[0];
  }
  return mask;
}

/**
 * Get array of masks
 * @param mask Mask or array of masks
 * @returns Always masks array
 */
function getMaskArray(mask: PhoneMask): string[] {
  return Array.isArray(mask) ? mask : [mask];
}

export function getMaskByIso(countriesPool: CountryProps[], iso: string) {
  let result;
  countriesPool.find((item: CountryProps) => {
    if (item.iso.toLowerCase() === iso) {
      result = `${getDefaultMask(item)}`.replace(")", " ")
        .replace("(", "");
    }
    return null;
  });

  return result;
}

/**
 * Does the concatenation of the country code and mask
 * @param itemCode
 * @param itemMask
 * @param maskIndex
 */
function concatenateCodeAndMask(itemCode: string, itemMask: PhoneMask, maskIndex = 0) {
  const comparedMask = Array.isArray(itemMask) ? itemMask[maskIndex] : itemMask;
  return convertToI18lClearPhone(`${itemCode}${comparedMask}`);
}

/**
 *
 * @param phoneNumber
 * @param phoneMask
 */
export function isPhoneCompleted(phoneNumber: string, phoneMask: string) {
  return (
    phoneNumber.replace(/\D/g, "").length
    === phoneMask.replaceAll("#", "9")
      .replace(/\D/g, "").length
  );
}

/**
 *
 * @param countries
 * @param countryCode
 * @param phoneNumber
 */
export function getCountryByPhone(
  countries: CountryProps[] | null,
  countryCode: string,
  phoneNumber: string,
) {
  const clearedPhone = convertToI18lClearPhone(phoneNumber);
  let match: CountryProps[] = [];
  const matchSet: Set<CountryProps> = new Set();
  const countriesList = countries;
  let exactMatch: CountryProps | null = null;

  for (let i = 0; i <= clearedPhone.length; i += 1) {
    const matchedPhone = clearedPhone.substring(0, i);

    if (!countriesList) {
      break;
    }

    countriesList.forEach((item: CountryProps) => {
      getMaskArray(item.mask)
        .forEach((mask, index) => {
          if ((concatenateCodeAndMask(item.code, mask, index) as string)
            .indexOf(matchedPhone) !== -1) {
            if (i === 0) {
              matchSet.add({
                ...item,
                testedMaskIndex: index,
              });
            }
            if (concatenateCodeAndMask(item.code, mask, index) === matchedPhone) {
              exactMatch = {
                ...item,
                testedMaskIndex: index,
              };
            }
          }
        });
      return convertToI18lClearPhone(`${item.code}${item.mask}`);
    });

    match = [...matchSet].filter((item) => {
      if (item.code === countryCode) {
        const maskLength = Array.isArray(item.mask) ? item.mask[item.testedMaskIndex].replace(/[^#]+/g, "").length : item.mask.replace(/[^#]+/g, "").length;
        return maskLength >= clearedPhone.length;
      }

      return null;
    });
  }

  if (match.length) {
    return exactMatch || match[0];
  }

  return null;
}

export const getErrorMessage = (phone: string, country: CountryProps): string | null =>
  (!isPhoneCompleted(phone, getCountryMask(country)) ? "Номер меньшей длины, чем нужно" : null);
