import * as Sentry from "@sentry/gatsby";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  // Sentry logging works only on prod to not exeed free version limits
  dsn: process.env.ENV === "prod" ? process.env.PROD_SENTRY_DATA_SOURCE_NAME : process.env.DEV_SENTRY_DATA_SOURCE_NAME,
  integrations: [new BrowserTracing(), new Sentry.Replay({ maskAllText: false })],
  tracesSampleRate: 1.0, // Adjust this value in production
  // You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});
