import { gql } from "@apollo/client";

export const GET_PAYMENT_STATE = gql`
  query getPaymentState($paymentID: Int!) {
    getPayment(paymentID: $paymentID) {
      id
      state
      isFirstPayment
      amount{
        amount
        currency
      }
    }
  }
`;
