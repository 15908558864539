import "/node_modules/flag-icons/css/flag-icons.min.css";

import React, { useEffect, useRef, useState } from "react";

import { convertToI18lClearPhone } from "@layout/modals/utils";
import { IMask, IMaskInput } from "react-imask";

import useOutsideClick from "@/hooks/useOutsideClick";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import { COUNTRIES_POOL } from "./constants";
import { CountryProps, PhoneInputProps } from "./types";
import {
  getCountryByNumber,
  getCountryByPhone,
  getCountryMask,
  getDefaultCountry,
  getDigitsOnly,
  getErrorMessage,
  getNumberWithoutCountryCode,
  prettifyMask,
} from "./utils";
import "./styles.scss";

const PhoneInput = (props: PhoneInputProps) => {
  const {
    id, phoneInitial, setPrevValueCallback, onChange, onSubmit,
  } = props;

  const [phone, setPhone] = useState(getNumberWithoutCountryCode(phoneInitial));
  const [isCountriesShown, setIsCountriesShown] = useState(false);
  const [country, setCountry] = useState<CountryProps | undefined>(
    getCountryByNumber(phoneInitial) ?? getDefaultCountry(),
  );
  const countriesDropdownToggle = useRef(null);

  const countriesDropdown = useOutsideClick(() => {
    setIsCountriesShown(false);
  }, "mousedown", [countriesDropdownToggle]);

  const toggleCountriesDropdown = () =>
    setIsCountriesShown((prevState) =>
      !prevState);

  const changeCountry = (changedCountry: CountryProps) => {
    setCountry(changedCountry);
    toggleCountriesDropdown();
  };

  const onPasteHandler = (event: any) => {
    // clean up the phone, because we can get a full length phone,
    // which will be cut to the length of the mask
    const numberDigits = getDigitsOnly(event.clipboardData.getData("Text"));
    const newCountry = getCountryByPhone(COUNTRIES_POOL, country.code, numberDigits);
    if (newCountry) setCountry(newCountry);
    setPhone(() => {
      if (numberDigits.length === 11 && country.iso.toLowerCase() === "ru") {
        // formatted value 8 (***) ***-**-**
        return (Number(numberDigits[0]) === 8 || Number(numberDigits[0]) === 7) ? `${numberDigits.substring(1)}` : numberDigits;
      }

      return numberDigits;
    });
  };

  const handleChange = (mask: IMask.InputMask<IMask.AnyMaskedOptions>, value = "") => {
    const newCountry = getCountryByPhone(COUNTRIES_POOL, country.code, value);
    if (newCountry) setCountry(newCountry);
    setPhone(getDigitsOnly(value));

    return value;
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.code) {
      case "Enter":
      case "NumpadEnter": {
        event.preventDefault();
        onChange(
          convertToI18lClearPhone(`${country.code}${phone}`),
          getErrorMessage(phone, country),
        );
        onSubmit();
        break;
      }
      default: {
        const inputElement = event.target as HTMLInputElement;
        const numberDigits = getDigitsOnly(inputElement.value);
        const enteredDigit = getDigitsOnly(event.code ? event.code : event.key);

        if (enteredDigit && numberDigits.length === getCountryMask(country)
          .replace(/[^#]+/g, "").length) {
          const newCountry = getCountryByPhone(COUNTRIES_POOL, country.code, `${numberDigits}${enteredDigit}`);

          if (newCountry) {
            setCountry(newCountry);
            setPhone(`${numberDigits}${enteredDigit}`);
          }
        }
        onChange(convertToI18lClearPhone(`${country.code}${numberDigits}${enteredDigit}`), null);
      }
    }
  };

  const onBlurHandler = () => {
    if (convertToI18lClearPhone(phone).length < 3) {
      setPhone("");
      onChange("", null);
    } else {
      onChange(
        convertToI18lClearPhone(`${country.code}${phone}`),
        getErrorMessage(phone, country),
      );
    }
  };

  useEffect(() => {
    setPrevValueCallback(`${country.code}${phone}`);
  }, [country.code, phone, setPrevValueCallback]);

  return (
    <div className="phone-input">
      <div className="input">
        <div className="auth__container-mask">{country.code}</div>
        <IMaskInput
          mask={String(prettifyMask(getCountryMask(country)))
            .replaceAll(/[0-9#\\]/g, "0")}
          value={phone}
          lazy={!(convertToI18lClearPhone(phone).length)}
          unmask={false} // true|false|'typed'
              // DO NOT USE onChange TO HANDLE CHANGES!
              // USE onAccept INSTEAD
          onAccept={
                // depending on prop above first argument is
                // `value` if `unmask=false`,
                // `unmaskedValue` if `unmask=true`,
                // `typedValue` if `unmask='typed'`
                (value, mask) =>
                  handleChange(mask, value as string)
              }
              // @ts-ignore
          id={id}
          onKeyDown={onKeyDownHandler}
          onPaste={(event: any) =>
            onPasteHandler(event)}
          onBlur={onBlurHandler}
          autoFocus
          type="tel"
          inputMode="tel"
              // input props also available
          placeholder={String(prettifyMask(getCountryMask(country)))
            .replaceAll("#", "_")}
        />
      </div>
      <div
        onClick={toggleCountriesDropdown}
        className={`input-group__icon input-group__icon--phone${isCountriesShown ? " open" : ""}`}
        ref={countriesDropdownToggle}
      >
        <Icon type={IconTypeEnum.Play} size={IconSizeEnum.Size16} />
        <span className={`fi fi-${country.iso.toLowerCase()}`} />
      </div>

      <div
        className={`countries-dropdown__container${isCountriesShown ? " show" : ""}`}
        onClick={toggleCountriesDropdown}
        ref={countriesDropdown}
      >
        <div className="countries-dropdown" onClick={(e) => { e.stopPropagation(); }}>
          <div className="countries-dropdown__close-sticky">
            <div className="countries-dropdown__close" onClick={toggleCountriesDropdown}>
              <Icon type={IconTypeEnum.Close} size={IconSizeEnum.Size24} />
            </div>
          </div>
          <div className="countries-dropdown__header h3">Выберите страну</div>
          {COUNTRIES_POOL.map((countryItem: CountryProps) =>
            (
              <div
                key={countryItem.iso}
                onClick={() =>
                  changeCountry(countryItem)}
                className={`countries-dropdown__item${countryItem.iso.toLowerCase() === country.iso.toLowerCase() ? " active" : ""}`}
              >
                <span className={`fi fi-${countryItem.iso.toLowerCase()}`} />
                <span className="countries-dropdown__item-name">{countryItem.name}</span>
                <span className="countries-dropdown__item-code">{countryItem.code}</span>
                {countryItem.iso.toLowerCase() === country.iso.toLowerCase()
                  && (
                    <Icon
                      className="countries-dropdown__item-active-icon"
                      type={IconTypeEnum.Tick}
                      size={IconSizeEnum.Size16}
                    />
                  )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PhoneInput;
